<template>
  <div>
    <!-- There are images in the gallery -->
    <v-card
      elevation="0"
      v-for="(galleryItem, index) in item.Value"
      :key="index"
      max-width="700"
      class="mb-2"
    >
      <v-card-text v-if="galleryItem.Name != ''" class="px-0">
        <v-img
          contain
          max-width="700"
          :src="
            serverUrl +
            '/images/' +
            websiteId +
            '/' +
            galleryItem.Name +
            '?token=' +
            userToken
          "
        >
          <v-app-bar
            flat
            dense
            color="rgba(0, 0, 0, 0)"
            v-if="(!item.ReadOnly && !needsDisable) || editItemAdmin"
          >
            <v-spacer></v-spacer>
            <v-btn
              v-if="item.AddAltTags || item.AddAltTags == null"
              depressed
              small
              color="primaryorange"
              icon
              @click="$set(hovergallery, index, !hovergallery[index])"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              depressed
              small
              color="primaryred"
              icon
              @click="deleteFile(item.Value, index)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-app-bar>
        </v-img>
        <v-expand-transition>
          <v-layout v-if="hovergallery[index]" row wrap>
            <template v-if="(!item.ReadOnly && !needsDisable) || editItemAdmin">
              <v-layout
                v-if="item.AddAltTags || item.AddAltTags == null"
                align-center
              >
                <v-select
                  v-if="item.Translate || item.Translate == undefined"
                  :items="itemLanguages"
                  v-model="itemDefaultLanguage"
                  single-line
                  class="altSelect-lang mt-2"
                  outlined
                  dense
                  hide-details="auto"
                ></v-select>

                <template
                  v-for="(
                    translatedValue, translatedValueIndex
                  ) in galleryItem.Alt"
                >
                  <v-text-field
                    :key="translatedValueIndex"
                    v-if="itemDefaultLanguage == translatedValueIndex"
                    :class="
                      item.Translate || item.Translate == undefined
                        ? 'ml-4 mt-2'
                        : 'mt-2'
                    "
                    placeholder="Alt tag"
                    v-model="galleryItem.Alt[translatedValueIndex]"
                    outlined
                    dense
                    hide-details="auto"
                  >
                  </v-text-field>
                </template>
              </v-layout>
            </template>
            <template v-else>
              {{ galleryItem.Alt[currentLanguage] }}
            </template>
          </v-layout>
        </v-expand-transition>
      </v-card-text>
    </v-card>

    <div v-if="!uploading">
      <template v-for="(image, index) in this.files">
        <h3 :key="index">
          <v-icon>mdi-image</v-icon>
          {{ image.name }}
          <v-btn
            depressed
            color="primaryred"
            icon
            @click="deleteSelectedFile(index)"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </h3>
      </template>
    </div>
    <preloader v-else></preloader>

    <input
      v-if="!item.ReadOnly || editItemAdmin"
      :ref="'galleryFileInput' + objectName"
      class="fileInput"
      multiple
      type="file"
      @change="setSelectedImages($event.target.files)"
    />
    <v-btn
      v-if="(!item.ReadOnly && !needsDisable) || editItemAdmin"
      depressed
      class="ml-0 mt-3 mr-3 edit-page-action-button"
      @click="$refs['galleryFileInput' + objectName].click()"
      color="primary"
      >{{ $t("Choose images") }}</v-btn
    >
    <v-btn
      v-if="(!item.ReadOnly && !needsDisable) || editItemAdmin"
      depressed
      color="primaryblue"
      class="ml-0 mt-3 white--text edit-page-action-button"
      :disabled="!files || uploading"
      :loading="uploading"
      @click="submitGalleryFiles(item)"
      >{{ $t("Upload") }}
      <v-icon right dark>mdi-cloud-upload</v-icon>
    </v-btn>

    <template v-if="$store.getters.editMode">
      <hr class="mt-5" />
      <v-row no-gutters class="my-5">
        <v-col
          cols="12"
          :lg="
            item.colWidth < 7
              ? item.colWidth < 5
                ? 12
                : 4
              : item.colWidth < 10
              ? 4
              : 2
          "
        >
          <v-layout column>
            <v-text-field
              v-model.number="item.colWidth"
              hide-details="auto"
              type="number"
              max="12"
              min="1"
              :label="$t('Column width')"
              outlined
              dense
            ></v-text-field>
            <v-switch
              flat
              :ripple="false"
              v-model="item.Translate"
              class="float-left mr-3"
              hide-details="auto"
              :label="$t('Translate item')"
            ></v-switch>
            <v-switch
              flat
              :ripple="false"
              v-model="item.AddAltTags"
              class="float-left mr-3"
              hide-details="auto"
              :label="$t('Add alt tags')"
            ></v-switch>
            <v-switch
              flat
              :ripple="false"
              v-model="item.ReadOnly"
              hide-details="auto"
              class="float-left mr-3"
              :label="$t('Read-only')"
            ></v-switch>
            <v-switch
              flat
              :ripple="false"
              v-model="item.RemoveCard"
              hide-details="auto"
              class="float-left mr-3"
              :label="$t('Remove frame')"
            ></v-switch>
          </v-layout>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: [Array, Object],
    },
    needsDisable: {
      type: Boolean,
    },
    websiteId: {
      type: String,
    },
    pageId: {
      type: String,
    },
    objectName: {
      type: [Array, Object, String],
    },
    editItemAdmin: {
      type: Boolean,
    },
  },
  data() {
    return {
      userToken: this.$auth.userToken(),
      serverUrl: this.$helpers.getServerUrl(),
      itemLanguages: this.getItemLanguages(),
      itemDefaultLanguage: this.getItemDefaultLanguage(),
      files: null,
      uploading: false,
      uploadError: null,
      hovergallery: [],
    };
  },
  computed: {
    currentLanguage() {
      return this.$i18n.locale();
    },
  },
  methods: {
    getItemDefaultLanguage() {
      return this.$config.contentLanguages[0];
    },
    getItemLanguages() {
      return this.$config.contentLanguages;
    },
    deleteFile(files, index) {
      files.splice(index, 1);
    },
    deleteSelectedFile(index) {
      this.files.splice(index, 1);
    },
    setSelectedImages(imagesList) {
      this.files = Array.prototype.slice.call(imagesList);
    },
    submitGalleryFiles(item) {
      this.uploading = true;
      this.$emit("update:saving", true);

      let formData = new FormData();
      for (var i = 0; i < this.files.length; i++) {
        formData.append("files", this.files[i]);
      }
      this.$request.post(
        "/content/imageupload/" + this.pageId,
        formData,
        (res) => {
          this.uploading = false;
          this.$emit("update:saving", false);
          this.$refs["galleryFileInput" + this.objectName].value = null;

          if (res.success) {
            if (item.Value == "") {
              item.Value = [];
            } else {
              item.Value = item.Value.filter((i) => i.Name != "");
            }

            for (let i = 0; i < res.data.length; i++) {
              item.Value[item.Value.length] = {
                Name: res.data[i],
                Alt: { Nl: "", Fr: "", En: "" },
              };
            }
            this.getItemLanguages();
            this.getItemDefaultLanguage();
            this.files = null;
            const toaster = {
              message: this.$t("Images uploaded successfully"),
              timeout: false,
              type: "SUCCESS",
              toastClassName: ["toaster-color-success", "toaster-layout"],
              bodyClassName: ["toaster-text-layout"],
              icon: "mdi-check-circle",
            };
            this.$store.dispatch("setToaster", toaster);
          } else {
            this.uploadError = res.message;
            const toaster = {
              message: res.message,
              timeout: false,
              type: "ERROR",
              toastClassName: ["toaster-color-error", "toaster-layout"],
              bodyClassName: ["toaster-text-layout"],
              icon: "mdi-alert-octagon",
            };
            this.$store.dispatch("setToaster", toaster);
          }
        }
      );
    },
  },
};
</script>

<style scoped>
.fileInput {
  display: none;
}
.mw-w {
  min-width: 2rem;
  max-width: 8rem;
}
.galleryImage {
  max-width: 100%;
  max-height: 350px;
}
.imageContainer {
  position: relative;
  max-width: 100%;

  display: table;
  background-color: #fff;
  border: 1px solid #e4e4e4;
  cursor: pointer;
}

.imageContainer img {
  opacity: 1;
  margin-bottom: -6px;
}

.imageContainer:hover img,
.imageContainer:focus img {
  opacity: 0.6;
}

.imageContainer .editContainer {
  background-color: #fff;
  display: none;
  width: 100%;
}

.imageContainer .editContainer .textInput {
  margin: 18px 0;
}

.imageContainer .editContainer .customRemoveButton {
  margin: 14px 0;
}

.imageContainer:hover .editContainer,
.imageContainer:focus .editContainer {
  display: flex;
}
</style>